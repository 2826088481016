import request from '@/util/request'

/**
 * 获取文件上传授权
 */
export function auth(params) {
    return request({
        url: '/api/v1/space/file/oauth',
        method: 'get',
        params
    })
}


//文件列表
export function files({start, limit}) {
    return request({
        url: '/api/v1/space/files',
        method: 'get',
        params: {
            start, limit
        }
    })
}

export function delFile({uuid}) {
    return request({
        url: '/api/v1/space/file/delete',
        method: 'get',
        params: {
            uuid
        }
    })
}
export function renameFile({uuid, name}) {
    return request({
        url: '/api/v1/space/file/reName',
        method: 'post',
        params: {
            uuid, name
        }
    })
}
